.tab button.active {
    background-color: rgb(11, 111, 179);
    color: white;
    font-weight: bold;
    font-family: AmazonEmber, Helvetica, sans-serif;
}

.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 26px;
    transition: 0.3s;
}


