

.displayed {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%) translateY(-50%);
}



.loadingLoginPage {
    margin: 0;
    padding: 5;
    font-family: ‘Arial Narrow’, sans-serif;
    font-weight: 100;
    font-size: 1.1em;
    color: #202729;
    text-align: center;
  }
  
  .loadingLoginPage span {
    position: relative;
    top: 0.63em;  
    display: inline-block;
    text-transform: uppercase;  
    opacity: 0;
    transform: rotateX(-90deg);
  }
  
  .let1 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.2s;
  }
  
  .let2 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.3s;
  }
  
  .let3 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.4s;
  }
  
  .let4 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.5s;
  
  }
  
  .let5 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.6s;
  }
  
  .let6 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.7s;
  }
  
  .let7 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.8s;
  }
  
  @keyframes drop {
      10% {
          opacity: 0.5;
      }
      20% {
          opacity: 1;
          top: 3.78em;
          transform: rotateX(-360deg);
      }
      80% {
          opacity: 1;
          top: 3.78em;
          transform: rotateX(-360deg);
      }
      90% {
          opacity: 0.5;
      }
      100% {
          opacity: 0;
          top: 6.94em
      }
  }