table{
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    padding:5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow-y: scroll
}

td{

  text-align: center;
  padding:5px;
  margin:auto;
}

th{
  text-align: center;
  margin:auto;
}

tbody{
  margin:5px;
}

