.agent-name {
    width: 33%%;
    color: white;
    background-color: #0F0F59;
    float: left;
    padding: 1px;
    margin: 2px;
    border: 2px solid red;
    display: inline-block;

}

.agent-state {
    width: 100;
    background-color: green;
    color: white;
    float: right;
    padding: 1px;
    margin: 2px;
    border: 2px solid black;
    display:inline-block;

}

.agent-container{
  margin:auto;
  text-align: center;
}
